import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Close } from '@mui/icons-material';
import {
  Dialog,
  AppBar,
  Button,
  Toolbar,
  IconButton,
  Typography,
  DialogContent
} from '@mui/material';


export default function DialogPopup({ open, handleClose, curPopup }) {
  useEffect(() => {
    if (!open) {
      handleClose();
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
    >
      <AppBar sx={{ position: 'relative' }} elevation={0}>
        <Toolbar>
          <Typography
            sx={{ ml: 2, flex: 1 }}
            variant="h6"
            component="div"
            textAlign="center"
          >
            {curPopup?.title}
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <Close />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DialogContent sx={{ px: 6, pt: 2, pb: 2, textAlign: 'center' }}>
        <Typography
          sx={{ mr: 2, mb: 2 }}
          variant="body1"
          component="div"
          textAlign="center"
        >
          {curPopup?.content}
        </Typography>
        {curPopup?.url?.length > 0 && <Button
          component="a"
          target="_blank"
          variant="contained"
          color="primary"
          href={curPopup?.url.startsWith('http') ? curPopup?.url : `https://${curPopup?.url}`}
          sx={{ mt: 2 }}
        >
          Dettagli
        </Button>}
      </DialogContent>
    </Dialog>
  );
}

DialogPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  curPopup: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
    url: PropTypes.string,
  }),
};
