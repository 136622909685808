import { alpha } from '@mui/material/styles';

export function pickersDay(theme) {
  return {
    MuiPickersDay: {
      variants: [
        {
          props: { variant: 'missing' },
          style: {
            borderColor: theme.palette.error.light,
            color: theme.palette.error.light,
            fontWeight: 600,
            '&:hover': {
              backgroundColor: alpha(theme.palette.error.light, 0.14),
            },
            '&.Mui-selected[variant="missing"], &.Mui-selected:focus, &.Mui-selected:active':
              {
                backgroundColor: theme.palette.error.light,
              },
          },
        },
        {
          props: { variant: 'completed' },
          style: {
            color: theme.palette.success.main,
            fontWeight: 600,
            '&.Mui-selected[variant="completed"], &.Mui-selected:focus, &.Mui-selected:active':
              {
                backgroundColor: theme.palette.success.main,
              },
          },
        },
        {
          props: { variant: 'standard' },
          style: {
            // background: '#FFF',
            // color: '#5D5D5D',
            '&.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus': {
              // background: '#FFF',
              // color: '#5D5D5D',
            }
          },
        },
      ],
      styleOverrides: {
        root: {
          border: '1px solid white',
          '&.Mui-selected': {
            border: 'none',
            boxShadow: '0px 1px 6px 3px rgba(0, 0, 0, 0.08)',
            '&[variant="standard"]': {
              background: 'inherit',
              color: 'inherit',
            },
          }
        },
      },
    },
  };
}
