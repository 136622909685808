import apiClient, { lambdaClient, publicClient } from './ApiService';

const getEmployees = (company_id) =>
  apiClient.get(`/company/${company_id}/employees`);

const exportEmployeesXLS = (company_id, ids = null, timeline = false) => apiClient.get(
  ids ? `/company/${company_id}/employees/export?ids=${ids.join(',')}&timeline=${timeline}` : `/company/${company_id}/employees/export?timeline=${timeline}`
);

const getEmployee = (company_id, employee_id) =>
  apiClient.get(`/company/${company_id}/employees/${employee_id}`);

const updateEmployee = (company_id, employee_id, data) =>
  apiClient.put(`/company/${company_id}/employees/${employee_id}`, data);

const updateEmployeeCareer = (company_id, employee_id, data) =>
  apiClient.put(`/company/${company_id}/employees/${employee_id}/career`, data);

const getCompany = (company_id) => apiClient.get(`/company/${company_id}`);

const updateCompany = (company_id, data) => apiClient.put(`/company/${company_id}`, data);

const getDocuments = (company_id) =>
  apiClient.get(`/company/${company_id}/documents`);

const getClockings = (company_id, from, to) =>
  apiClient.get(`/company/${company_id}/clockings?from=${from}&to=${to}`);

const createFolderDocument = (company_id, data) =>
  apiClient.post(`/company/${company_id}/documents/folder`, data);

const createDocument = (company_id, path) =>
  apiClient.post(`/company/${company_id}/documents`, { key: path });

const downloadDocument = (company_id, key) =>
  apiClient.get(`/company/${company_id}/documents/download?key=${key}`);

const deleteDocument = (company_id, key) =>
  apiClient.delete(`/company/${company_id}/documents?key=${key}`);

const createEmployee = (company_id, data) =>
  apiClient.post(`/company/${company_id}/employees`, data);

const getImportEmployees = (company_id) =>
  apiClient.get(`/company/${company_id}/employee/import`);

const importEmployees = (company_id, data) =>
  apiClient.post(`/company/${company_id}/employee/import`, data);

const getCompanyTimesheet = (company_id, year, month) =>
  apiClient.get(`/company/${company_id}/timesheets/${year}/${month}`);

const getCompanyTimesheetAttachments = (company_id, year, month) =>
  apiClient.get(`/company/${company_id}/timesheets/${year}/${month}/attachments`);

const updateCompanyTimesheet = (company_id, year, month, data) =>
  apiClient.post(`/company/${company_id}/timesheets/${year}/${month}`, data);

const getTimesheets = (company_id) =>
  apiClient.get(`/company/${company_id}/timesheets`);

const closeTimesheets = (company_id, timesheet_ids) =>
  apiClient.post(`/company/${company_id}/timesheets/close`, { timesheet_ids });

const checkTimesheet = (company_id, year, month, status) =>
  apiClient.get(
    `/company/${company_id}/timesheets/check?year=${year}&month=${month}&status=${status}`
  );

const confirmTimesheet = (company_id, year, month) =>
  apiClient.get(
    `/company/${company_id}/timesheets/confirm?year=${year}&month=${month}`
  );

const sendTimesheetToPayroll = (company_id, year, month) =>
  apiClient.get(
    `/company/${company_id}/timesheets/send-to-payroll?year=${year}&month=${month}`
  );

const exportPDFTimesheet = (company_id, year, month) =>
  apiClient.get(
    `/company/${company_id}/timesheets/pdf?year=${year}&month=${month}`
  );

const updateEmployeeTimesheet = (company_id, employee_id, timesheet) =>
  apiClient.put(
    `/company/${company_id}/employees/${employee_id}/timesheet`,
    timesheet
  );

const getEmployeeTimesheet = (company_id, employee_id, year, month) =>
  apiClient.get(
    `/company/${company_id}/employees/${employee_id}/timesheet?year=${year}&month=${month}`
  );

const exportEmployeeTimesheetXLS = (company_id, employee_id, year, month) => apiClient.get(
  `/company/${company_id}/employees/${employee_id}/timesheet/export?year=${year}&month=${month}`
);

const exportTimesheetsXLS = (company_id, year, month, ids = null) => apiClient.get(
  ids ? `/company/${company_id}/timesheet/export?year=${year}&month=${month}&ids=${ids.join(',')}` : `/company/${company_id}/employees/export?year=${year}&month=${month}`
);

const getEmployeeClockings = (company_id, employee_id, date) =>
  apiClient.get(
    `/company/${company_id}/employees/${employee_id}/clockings?date=${date}`
  );

const getEmployeeTimeline = (company_id, employee_id) =>
  apiClient.get(`/company/${company_id}/employees/${employee_id}/timeline`);

const addEmployeeTimeline = (company_id, employee_id, value, valid_from) =>
  apiClient.post(`/company/${company_id}/employees/${employee_id}/timeline`, {
    value,
    valid_from
  });

const updateEmployeeTimeline = (company_id, employee_id, timeline_id, data) =>
  apiClient.put(
    `/company/${company_id}/employees/${employee_id}/timeline/${timeline_id}`,
    data
  );

const deleteEmployeeTimeline = (company_id, employee_id, timeline_id) =>
  apiClient.delete(
    `/company/${company_id}/employees/${employee_id}/timeline/${timeline_id}`
  );

const deleteEmployeeClocking = (clocking_id) =>
  apiClient.delete(`clocking/${clocking_id}`);

const addClocking = (data) => apiClient.post(`clocking/manual`, data);

const qrCodeClocking = (data) => publicClient.post(`clocking/qr-code`, data);

const checkDeviceCode = (data) => publicClient.post(`clocking/check-device`, data);

const updateClocking = (clocking_id, data) =>
  apiClient.put(`clocking/${clocking_id}`, data);

const getTimesheetRequests = (company_id) =>
  apiClient.get(`/company/${company_id}/timesheets/requests`);

const getTimesheetRequest = (company_id, request_id, token = null) =>
  apiClient.get(`/company/${company_id}/timesheets/requests/${request_id}?token=${token}`);

const approveTimesheetRequest = (company_id, request_id, request) =>
  apiClient.post(
    `/company/${company_id}/timesheets/requests/${request_id}/approve`,
    request
  );

const rejectTimesheetRequest = (company_id, request_id, request) =>
  apiClient.post(
    `/company/${company_id}/timesheets/requests/${request_id}/reject`,
    request
  );

const cancelTimesheetRequest = async (company_id, request_id) =>
  apiClient.post(`company/${company_id}/timesheets/requests/${request_id}/cancel`, {})

const dismissCancelTimesheetRequest = async (company_id, request_id) =>
  apiClient.post(`company/${company_id}/timesheets/requests/${request_id}/dismiss-cancel`, {})

const getOffices = (company_id) =>
  apiClient.get(`/company/${company_id}/offices`);

const updateOffice = (company_id, office_id, data) =>
  apiClient.put(`/company/${company_id}/offices/${office_id}`, data);

const createOffice = (company_id, data) =>
  apiClient.post(`/company/${company_id}/offices`, data);

const deleteOffice = (company_id, office_id) =>
  apiClient.delete(`/company/${company_id}/offices/${office_id}`);

const getTimeProfiles = (company_id) =>
  apiClient.get(`/company/${company_id}/time-profiles`);

const getTimesheetCategories = (company_id) =>
  apiClient.get(`/company/${company_id}/timesheet-categories`);

const getRequestsCategories = (company_id) => apiClient.get(`/company/${company_id}/requests-categories`)

const updateTimesheet = (data) => apiClient.put(`/timesheet`, data);

const updateMonthlyTimesheet = (data) => apiClient.put(`/timesheet/monthly`, data);

const rangeUpdateTimesheet = (data) =>
  apiClient.put(`/timesheet/range-justify`, data);

const getAnomalies = (company_id) =>
  apiClient.get(`/company/${company_id}/anomalies`);

const getEmployeeDailyAnomalies = (company_id, employee_id, date) =>
  apiClient.get(
    `/company/${company_id}/anomalies?date=${date}&employee_id=${employee_id}`
  );

const deleteAnomaly = (
  company_id,
  employee_id,
  year,
  month,
  day,
  anomaly_code
) =>
  apiClient.post(`/company/${company_id}/anomalies/delete`, {
    employee_id,
    year,
    month,
    day,
    anomaly_code
  });

const getDailyAnomalies = (company_id, date) =>
  apiClient.get(`/company/${company_id}/anomalies?date=${date}`);

const computeEmployeeTimesheet = (employee_id, date) =>
  apiClient.post(
    `/timesheet/compute-day?date=${date}&employee_id=${employee_id}`
  );

const computeEmployeeMonthTimesheet = (employee_id, date) =>
  apiClient.post(
    `/timesheet/compute-month?date=${date}&employee_id=${employee_id}`
  );

const createEmployeeTimesheet = (employee_id, date) =>
  lambdaClient.get(
    `/createEmployeeTimesheet?date=${date}&employee_id=${employee_id}`
  );

const createExpenseReport = (company_id, data) =>
  apiClient.post(`/company/${company_id}/expense-report`, data);

const getExpenseReports = (company_id) =>
  apiClient.get(`/company/${company_id}/expense-report`);

const exportExpenseReportsXLS = (company_id, ids = null) => apiClient.get(
  ids ? `/company/${company_id}/expense-report/export?ids=${ids.join(',')}` : `/company/${company_id}/expense-report/export`
);

const getExpenseReportAttachments = (company_id, expense_report_id) =>
  apiClient.get(
    `/company/${company_id}/expense-report/${expense_report_id}/attachments`
  );

const approveExpenseReport = (company_id, data) =>
  apiClient.put(`/company/${company_id}/expense-report/${data.id}`, data);

const sendExpenseReportsToPayroll = (company_id, data) =>
  apiClient.post(`/company/${company_id}/expense-report/send-to-payroll`, data);

const addHelpTicket = (company_id, data) =>
  apiClient.post(`/company/${company_id}/help-desk`, data);

const getHelpTickets = (company_id) =>
  apiClient.get(`/company/${company_id}/help-desk`);

const getHelpTicket = (company_id, ticket_id) =>
  apiClient.get(`/company/${company_id}/help-desk/${ticket_id}`);

const getTools = (company_id) => apiClient.get(`/company/${company_id}/tools`);

const getDevices = (company_id) => apiClient.get(`/company/${company_id}/devices`);

const createDevice = (company_id, data) => apiClient.post(`/company/${company_id}/devices`, data);

const deleteDevice = (company_id, device_code) => apiClient.delete(`/company/${company_id}/devices/${device_code}`);

const createTool = (company_id, data) =>
  apiClient.post(`/company/${company_id}/tools`, data);

const deleteTool = (company_id, tool_id) => apiClient.delete(`/company/${company_id}/tools/${tool_id}`);

const getCostCenters = (company_id) =>
  apiClient.get(`/company/${company_id}/cost-centers`);

const createCostCenter = (company_id, data) =>
  apiClient.post(`/company/${company_id}/cost-centers`, data);

const deleteCostCenter = (company_id, cost_center_code) =>
  apiClient.delete(`/company/${company_id}/cost-centers/${cost_center_code}`);

const getEmployeeTools = (company_id, employee_id) =>
  apiClient.get(`/company/${company_id}/employees/${employee_id}/tools`);

const deleteEmployeeTool = (company_id, employee_id, tool_id) =>
  apiClient.delete(
    `/company/${company_id}/employees/${employee_id}/tools/${tool_id}`
  );

const createEmployeeTool = (company_id, employee_id, data) =>
  apiClient.post(`/company/${company_id}/employees/${employee_id}/tools`, data);

const updateEmployeeTool = (company_id, employee_id, tool_id, data) =>
  apiClient.put(
    `/company/${company_id}/employees/${employee_id}/tools/${tool_id}`,
    data
  );

const requestEmployeeTermination = (company_id, employee_id, data) =>
  apiClient.put(
    `/company/${company_id}/employees/${employee_id}/termination`,
    data
  );

const getEmployeeRequests = (company_id, employee_id) =>
  apiClient.get(
    `/company/${company_id}/employees/${employee_id}/timesheets/requests`
  );

const getEmployeeRequestsByDate = (company_id, employee_id, date) =>
  apiClient.get(
    `/company/${company_id}/employees/${employee_id}/timesheets/requests?date=${date}`
  );

const getEmployeeDocuments = (company_id, employee_id) =>
  apiClient.get(`/company/${company_id}/employees/${employee_id}/documents`);

const createEmployeeFolders = (company_id, employee_id) =>
  apiClient.post(
    `/company/${company_id}/employees/${employee_id}/documents/default-folders`
  );

const createEmployeeDocument = (company_id, employee_id, key) =>
  apiClient.post(`/company/${company_id}/employees/${employee_id}/documents`, {
    key
  });

const downloadEmployeeDocument = (company_id, employee_id, key) =>
  apiClient.get(
    `/company/${company_id}/employees/${employee_id}/documents/download?key=${key}`
  );

const deleteEmployeeDocument = (company_id, employee_id, key) =>
  apiClient.delete(
    `/company/${company_id}/employees/${employee_id}/documents?key=${key}`
  );

const getEmployeeCalendarEvents = (company_id, employee_id) =>
  apiClient.get(
    `/company/${company_id}/employees/${employee_id}/calendar-events`
  );

const createCalendarEvent = (company_id, data) =>
  apiClient.post(`/company/${company_id}/calendar-events`, data);

const getCalendarEvents = (company_id) =>
  apiClient.get(`/company/${company_id}/calendar-events`);

const deleteCalendarEvent = (company_id, event_id) =>
  apiClient.delete(`/company/${company_id}/calendar-events/${event_id}`);

const getImports = (company_id, matricola = null, category, date) =>
  apiClient.get(
    matricola ? `/company/${company_id}/imports?matricola=${matricola}&category=${category}&date=${date}`
      : `/company/${company_id}/imports?category=${category}&date=${date}`
  );

const getImportTypes = (company_id) =>
  apiClient.get(`/company/${company_id}/import-types`);

const getLaborCost = (company_id) =>
  apiClient.get(`/company/${company_id}/labor-cost`);

const exportImportData = (company_id, category, start, end) =>
  apiClient.get(`/company/${company_id}/imports/export?category=${category}&start=${start}&end=${end}`);

const putSignedUrl = (company_id, path) =>
  apiClient.post(`/company/${company_id}/documents`, { key: path });

const updateTableTimesheet = (employee_id, rows, year, month) =>
  apiClient.put(`/timesheet/table`, {
    employee_id,
    rows,
    year,
    month
  });

const createTimesheet = (company_id, employee_id) => apiClient.post(`/timesheet/create-timesheet`, {
  company_id,
  employee_id
});

const getExtraFields = (company_id) =>
  apiClient.get(`/company/${company_id}/timesheet-extra`);

const getEmployeeExtraFields = (company_id, employee_id, year, month) =>
  apiClient.get(
    `/company/${company_id}/timesheet-extra/${employee_id}/${year}/${month}`
  );

const updateExtraFields = (company_id, employee_id, year, month, data) =>
  apiClient.put(
    `/company/${company_id}/timesheet-extra/${employee_id}/${year}/${month}`,
    data
  );

const checkEmployeeData = (company_id, data, update = false) =>
  apiClient.post(update ? `/company/${company_id}/checkEmployee?update=true` : `/company/${company_id}/checkEmployee`, data);

const getTimesheetStats = (company_id, from_year, from_month, to_year, to_month) =>
  apiClient.get(`/company/${company_id}/timesheet/stats/${from_year}/${from_month}/${to_year}/${to_month}`);

const exportStatsXLS = (company_id, from_year, from_month, to_year, to_month) =>
  apiClient.get(`/company/${company_id}/timesheet/stats/${from_year}/${from_month}/${to_year}/${to_month}?excel=true`);

const getLetters = (company_id) => apiClient.get(`/company/${company_id}/letters`);

const getLetterAttachments = (company_id, letter_id) => apiClient.get(`/company/${company_id}/letters/${letter_id}/attachments`);

const getSupervisors = (company_id) => apiClient.get(`/company/${company_id}/supervisors`);

const addSupervisor = (company_id, data) => apiClient.post(`/company/${company_id}/supervisors`, data);

const deleteSupervisor = (company_id, supervisor_id) => apiClient.delete(`/company/${company_id}/supervisors/${supervisor_id}`);

const getManagers = (company_id) => apiClient.get(`/company/${company_id}/managers`);

const addManager = (company_id, data) => apiClient.post(`/company/${company_id}/managers`, data);

const removeManager = (company_id, email) => apiClient.delete(`/company/${company_id}/managers/${email}`);

const getTimesheetAttachments = (employee_id, date) => apiClient.get(`/timesheet/attachments?date=${date}&employee_id=${employee_id}`);

const requestReopenTimesheet = (company_id, year, month) => apiClient.post(`/company/${company_id}/timesheets/request-reopen`, {
  year,
  month
});

const reopenTimesheet = (company_id, timesheet_id) => apiClient.post(`/company/${company_id}/timesheets/${timesheet_id}/reopen`);

const getPopups = (company_id) => apiClient.get(`/company/${company_id}/popups`);

const CompanyService = {
  createTimesheet,
  getEmployees,
  exportEmployeesXLS,
  getCompany,
  updateCompany,
  getDocuments,
  getClockings,
  createFolderDocument,
  createDocument,
  downloadDocument,
  deleteDocument,
  createEmployee,
  getImportEmployees,
  getCompanyTimesheet,
  getCompanyTimesheetAttachments,
  updateCompanyTimesheet,
  importEmployees,
  getTimesheets,
  closeTimesheets,
  checkTimesheet,
  confirmTimesheet,
  sendTimesheetToPayroll,
  exportPDFTimesheet,
  updateEmployeeTimesheet,
  getTimesheetRequests,
  getTimesheetRequest,
  approveTimesheetRequest,
  rejectTimesheetRequest,
  cancelTimesheetRequest,
  dismissCancelTimesheetRequest,
  getEmployeeTimesheet,
  exportEmployeeTimesheetXLS,
  exportTimesheetsXLS,
  getEmployeeClockings,
  getEmployeeTimeline,
  addEmployeeTimeline,
  updateEmployeeTimeline,
  deleteEmployeeTimeline,
  deleteEmployeeClocking,
  addClocking,
  qrCodeClocking,
  checkDeviceCode,
  updateClocking,
  getEmployee,
  updateEmployee,
  updateEmployeeCareer,
  getOffices,
  createOffice,
  updateOffice,
  deleteOffice,
  getTimeProfiles,
  getTimesheetCategories,
  getRequestsCategories,
  updateTimesheet,
  updateMonthlyTimesheet,
  rangeUpdateTimesheet,
  getAnomalies,
  getEmployeeDailyAnomalies,
  getDailyAnomalies,
  deleteAnomaly,
  computeEmployeeTimesheet,
  computeEmployeeMonthTimesheet,
  createEmployeeTimesheet,
  createExpenseReport,
  getExpenseReports,
  exportExpenseReportsXLS,
  approveExpenseReport,
  getExpenseReportAttachments,
  sendExpenseReportsToPayroll,
  addHelpTicket,
  getHelpTickets,
  getHelpTicket,
  getTools,
  getDevices,
  createDevice,
  deleteDevice,
  createTool,
  deleteTool,
  getCostCenters,
  createCostCenter,
  deleteCostCenter,
  createEmployeeTool,
  updateEmployeeTool,
  getEmployeeTools,
  deleteEmployeeTool,
  requestEmployeeTermination,
  getEmployeeRequests,
  getEmployeeRequestsByDate,
  getEmployeeDocuments,
  createEmployeeFolders,
  createEmployeeDocument,
  downloadEmployeeDocument,
  deleteEmployeeDocument,
  getEmployeeCalendarEvents,
  createCalendarEvent,
  getCalendarEvents,
  deleteCalendarEvent,
  getImports,
  getImportTypes,
  getLaborCost,
  exportImportData,
  updateTableTimesheet,
  putSignedUrl,
  getExtraFields,
  getEmployeeExtraFields,
  updateExtraFields,
  checkEmployeeData,
  getTimesheetStats,
  exportStatsXLS,
  getLetters,
  getLetterAttachments,
  getSupervisors,
  addSupervisor,
  deleteSupervisor,
  getManagers,
  addManager,
  removeManager,
  getTimesheetAttachments,
  requestReopenTimesheet,
  reopenTimesheet,
  getPopups
};
export default CompanyService;
