import axios from 'axios';

const apiClient = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  headers: {
    Accept: 'application/json',
  },
});

export const publicClient = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  headers: {
    Accept: 'application/json',
  },
});

export const lambdaClient = axios.create({
  baseURL: import.meta.env.VITE_LAMBDA_URL,
  headers: {
    Accept: 'application/json',
  },
});

export default apiClient;
