import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

import Box from '@mui/material/Box';

import { useResponsive } from 'src/hooks/use-responsive';

import { NAV, HEADER } from '../config-layout';
import { useAuthContext } from '../../auth/hooks';
import DialogPopup from '../../sections/DialogPopup';
import CompanyService from '../../services/CompanyService';
import { useSettingsContext } from '../../components/settings/index';

// ----------------------------------------------------------------------

const SPACING = 8;

export default function Main({ children, sx, ...other }) {
  const { user } = useAuthContext();

  const settings = useSettingsContext();

  const lgUp = useResponsive('up', 'lg');

  const isNavMini = settings.themeLayout === 'mini';

  const [open, setOpen] = useState(false);

  const [popups, setPopups] = useState([]);

  const [curPopupIndex, setCurPopupIndex] = useState(null);

  const fetchPopups = async () => {
    const res = await CompanyService.getPopups(user.data.current_company);
    setPopups(res.data);
    if (res.data.length > 0) {
      setCurPopupIndex(0);
      setOpen(true);
    }
  }

  const handleClose = () => {
    setOpen(false);
    if (curPopupIndex < popups.length - 1) {
      setCurPopupIndex(curPopupIndex + 1);
      setOpen(true);
    }
  }

  useEffect(() => {
    if (!user.data?.current_company || !user.data?.groups?.includes('Azienda')) {
      return;
    }
    fetchPopups().then();
    // Load list of popups
  }, []);

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        minHeight: 1,
        display: 'flex',
        flexDirection: 'column',
        py: `${HEADER.H_MOBILE + SPACING}px`,
        ...(lgUp && {
          px: 2,
          py: `${HEADER.H_DESKTOP + SPACING}px`,
          width: `calc(100% - ${NAV.W_VERTICAL}px)`,
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI}px)`,
          }),
        }),
        ...sx,
      }}
      {...other}
    >
      {children}
      <DialogPopup handleClose={handleClose} open={open} curPopup={popups[curPopupIndex] || {}} />
    </Box>
  );
}

Main.propTypes = {
  children: PropTypes.node,
  sx: PropTypes.object,
};
