import { useState, useEffect } from 'react';
import Iconify from '@heyjob-dash/shared/components/iconify';

import { Accordion, Container, Typography, AccordionSummary, AccordionDetails } from '@mui/material';

import { paths } from '../routes/paths';
import { useAuthContext } from '../auth/hooks';
import GeneralService from '../services/GeneralService';
import CustomBreadcrumbs from '../components/custom-breadcrumbs';

export default function FAQPage() {

  const { user } = useAuthContext();

  const [faqs, setFaqs] = useState({});

  async function fetchFaqs() {
    const res = await GeneralService.getFaqs(user.data.companies[user.data.current_company].payroll_id);
    // group by category
    const grouped = res.data.reduce((acc, item) => {
      if (!acc[item.category]) {
        acc[item.category] = [];
      }
      acc[item.category].push(item);
      return acc;
    }, {});
    setFaqs(grouped);
  }

  useEffect(() => {
    fetchFaqs().then();
  }, []);

  return (<Container maxWidth={false}>
      <CustomBreadcrumbs
        heading="FAQ"
        links={[
          {
            name: 'Dashboard',
            href: paths.dashboard.root,
          },
          {
            name: 'FAQ',
          }
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />


      {Object.entries(faqs).map(([key, list], index) => (
        <div key={index}>
          <Typography variant="h5" sx={{ my: 2 }}>{key}</Typography>
          {list.map((accordion) => (
            <Accordion key={accordion.id}>
              <AccordionSummary expandIcon={<Iconify icon="eva:arrow-ios-downward-fill" />}>
                <Typography variant="subtitle1">{accordion.question}</Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Typography>{accordion.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      ))}

    </Container>
  );

}
