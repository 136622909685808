import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { AuthGuard } from 'src/auth/guard';
import DashboardLayout from 'src/layouts/dashboard';

import { LoadingScreen } from 'src/components/loading-screen';

import FAQPage from '../../pages/FAQPage';

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/HomePage'));
const EmployeesPage = lazy(() => import('src/pages/employee/AnagrafichePage'));
const CreateAnagraficaPage = lazy(() =>
  import('src/pages/employee/CreateAnagraficaPage')
);
const TimesheetPage = lazy(() => import('src/pages/timesheet/TimesheetPage'));
const DocumentsPage = lazy(() => import('src/pages/DocumentsPage'));
const ScadenzarioPage = lazy(() => import('src/pages/ScadenzarioPage'));
const ExpenseReportsPage = lazy(() => import('src/pages/ExpenseReportsPage'));
const ComunicazioniPage = lazy(() => import('src/pages/ComunicazioniPage'));
const HelpDeskPage = lazy(() => import('src/pages/HelpDeskPage'));
const ConfigPage = lazy(() => import('src/pages/ConfigPage'));
const AccountPage = lazy(() => import('src/pages/AccountPage'));
const StatistichePage = lazy(() => import('src/pages/StatistichePage'));
const LettersPage = lazy(() => import('src/pages/LettersPage'));
const RequestsPage = lazy(() => import('src/pages/RequestsPage'));
const EmployeePage = lazy(() => import('src/pages/employee/AnagraficaPage'));
const MonthlyTimesheetPage = lazy(() =>
  import('src/pages/timesheet/MonthlyTimesheetPage')
);
const NewsPage = lazy(() => import('src/pages/NewsPage'));
const NewsDetailPage = lazy(() => import('src/pages/NewsDetailPage'));
const PayrollDataPage = lazy(() => import('src/pages/PayrollDataPage'));
const ClockingsPage = lazy(() => import('src/pages/ClockingsPage'));
const ClockingPlacesPage = lazy(() => import('../../pages/ClockingPlacesPage'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: '',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: 'employees', element: <EmployeesPage /> },
      { path: 'employees/new', element: <CreateAnagraficaPage /> },
      { path: 'employees/:employee_id', element: <EmployeePage /> },
      { path: 'timesheet', element: <TimesheetPage /> },
      { path: 'timesheet/:year/:month', element: <TimesheetPage /> },
      {
        path: 'timesheet/:year/:month/:employee_id',
        element: <MonthlyTimesheetPage />,
      },
      { path: 'documents', element: <DocumentsPage /> },
      { path: 'timetable', element: <ScadenzarioPage /> },
      { path: 'note-spese', element: <ExpenseReportsPage /> },
      { path: 'comunicazioni', element: <ComunicazioniPage /> },
      { path: 'letters', element: <LettersPage /> },
      { path: 'help-desk', element: <HelpDeskPage /> },
      { path: 'stats', element: <StatistichePage /> },
      { path: 'config', element: <ConfigPage /> },
      { path: 'config-clocking-places', element: <ClockingPlacesPage /> },
      { path: 'account', element: <AccountPage /> },
      { path: 'requests', element: <RequestsPage /> },
      { path: 'news', element: <NewsPage /> },
      { path: 'news/:news_id', element: <NewsDetailPage /> },
      { path: 'faq', element: <FAQPage /> },
      { path: 'clockings', element: <ClockingsPage /> },
      { path: 'payroll-data', element: <PayrollDataPage /> },
      // { path: 'mail', element: <MailPage /> },
      // { path: 'chat', element: <ChatPage /> },
      // { path: 'calendar', element: <CalendarPage /> },
      // { path: 'kanban', element: <KanbanPage /> },
      // { path: 'permission', element: <PermissionDeniedPage /> },
      // { path: 'blank', element: <BlankPage /> },
      // { path: 'booking', element: <OverviewBookingPage /> },
      // { path: 'file', element: <OverviewFilePage /> },
      // {
      //   path: 'user',
      //   children: [
      //     { element: <UserProfilePage />, index: true },
      //     { path: 'profile', element: <UserProfilePage /> },
      //     { path: 'cards', element: <UserCardsPage /> },
      //     { path: 'list', element: <UserListPage /> },
      //     { path: 'new', element: <UserCreatePage /> },
      //     { path: ':id/edit', element: <UserEditPage /> },
      //     { path: 'account', element: <UserAccountPage /> },
      //   ],
      // },
      // {
      //   path: 'product',
      //   children: [
      //     { element: <ProductListPage />, index: true },
      //     { path: 'list', element: <ProductListPage /> },
      //     { path: ':id', element: <ProductDetailsPage /> },
      //     { path: 'new', element: <ProductCreatePage /> },
      //     { path: ':id/edit', element: <ProductEditPage /> },
      //   ],
      // },
      // {
      //   path: 'order',
      //   children: [
      //     { element: <OrderListPage />, index: true },
      //     { path: 'list', element: <OrderListPage /> },
      //     { path: ':id', element: <OrderDetailsPage /> },
      //   ],
      // },
      // {
      //   path: 'invoice',
      //   children: [
      //     { element: <InvoiceListPage />, index: true },
      //     { path: 'list', element: <InvoiceListPage /> },
      //     { path: ':id', element: <InvoiceDetailsPage /> },
      //     { path: ':id/edit', element: <InvoiceEditPage /> },
      //     { path: 'new', element: <InvoiceCreatePage /> },
      //   ],
      // },
      // {
      //   path: 'post',
      //   children: [
      //     { element: <BlogPostsPage />, index: true },
      //     { path: 'list', element: <BlogPostsPage /> },
      //     { path: ':title', element: <BlogPostPage /> },
      //     { path: ':title/edit', element: <BlogEditPostPage /> },
      //     { path: 'new', element: <BlogNewPostPage /> },
      //   ],
      // },
      // {
      //   path: 'job',
      //   children: [
      //     { element: <JobListPage />, index: true },
      //     { path: 'list', element: <JobListPage /> },
      //     { path: ':id', element: <JobDetailsPage /> },
      //     { path: 'new', element: <JobCreatePage /> },
      //     { path: ':id/edit', element: <JobEditPage /> },
      //   ],
      // },
      // {
      //   path: 'tour',
      //   children: [
      //     { element: <TourListPage />, index: true },
      //     { path: 'list', element: <TourListPage /> },
      //     { path: ':id', element: <TourDetailsPage /> },
      //     { path: 'new', element: <TourCreatePage /> },
      //     { path: ':id/edit', element: <TourEditPage /> },
      //   ],
      // },
    ],
  },
];
