import { useState } from 'react';
import PropTypes from 'prop-types';
import { bgBlur } from 'src/theme/css';

import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';

import Logo from 'src/components/logo';
import SvgColor from 'src/components/svg-color';
import { useSettingsContext } from 'src/components/settings';

import { NAV, HEADER } from '../config-layout';
import AccountPopover from '../common/account-popover';
import { useAuthContext } from '../../auth/hooks/index';
import DarkModeButton from '../common/dark-mode-button';
import SelectCompanyDialog from '../../sections/SelectCompanyDialog';
import { WorkspacesPopover } from '../components/workspaces-popover';

// ----------------------------------------------------------------------

export default function Header({ onOpenNav }) {
  const theme = useTheme();

  const settings = useSettingsContext();

  const isNavHorizontal = settings.themeLayout === 'horizontal';

  const isNavMini = settings.themeLayout === 'mini';

  const lgUp = useResponsive('up', 'lg');

  const offset = useOffSetTop(HEADER.H_DESKTOP);

  const offsetTop = offset && !isNavHorizontal;

  const [openSelectCompany, setOpenSelectCompany] = useState(false);
  const { user } = useAuthContext();

  const renderContent = (
    <>
      {lgUp && isNavHorizontal && (
        <Logo sx={{ mr: 2.5 }} src={user?.data?.current_company && user?.data?.companies[user?.data?.current_company]?.logo_url} />
      )}

      {!lgUp && (
        <IconButton onClick={onOpenNav}>
          <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" />
        </IconButton>
      )}

      {lgUp && user.data?.companies && <WorkspacesPopover data-slot="workspaces" data={user.data?.companies
        ? Object.values(user.data.companies).map((company, index) => ({
          id: company.id,
          name: company.name,
          logo: company.logo_url
        }))
        : []} />}

      {/* lgUp &&
        <Button sx={{textTransform: 'none', mb: 1}} startIcon={<ArrowBackIosNewRounded/>} color="primary" onClick={() => navigate('/timesheet')}>
          {location.pathname}
        </Button>
      */}

      {/* <Searchbar /> */}

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1 }}
      >
        {/* <LanguagePopover/> */}

        {/* <NotificationsPopover/> */}

        {/* <ContactsPopover /> */}

        <DarkModeButton />

        <AccountPopover />
      </Stack>

      <SelectCompanyDialog
        open={openSelectCompany}
        onClose={() => setOpenSelectCompany(false)}
      />
    </>
  );

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter
        }),
        ...(lgUp && {
          width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
          height: HEADER.H_DESKTOP,
          ...(offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI + 1}px)`
          })
        })
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 }
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  onOpenNav: PropTypes.func
};
