import 'src/locales/i18n';
import { AuthProvider } from 'src/auth/context/amplify';

import Router from 'src/routes/sections';

import ProgressBar from 'src/components/progress-bar';

import ThemeProvider from './theme/index';
import { LocalizationProvider } from './locales/index';
import { useScrollToTop } from './hooks/use-scroll-to-top';
import { MotionLazy } from './components/animate/motion-lazy';
import { SettingsProvider } from './components/settings/index';
import SnackbarProvider from './components/snackbar/snackbar-provider';

export const App = () => {
  useScrollToTop();

  return (
    <AuthProvider>
      <LocalizationProvider>
        <SettingsProvider
          defaultSettings={{
            themeMode: 'light', // 'light' | 'dark'
            themeDirection: 'ltr', //  'rtl' | 'ltr'
            themeContrast: 'default', // 'default' | 'bold'
            themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
            themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
            themeStretch: false,
          }}
        >
          <ThemeProvider>
            <MotionLazy>
              <SnackbarProvider>
                <ProgressBar />
                <Router />
              </SnackbarProvider>
            </MotionLazy>
          </ThemeProvider>
        </SettingsProvider>
      </LocalizationProvider>
    </AuthProvider>
  );
};
