import merge from 'lodash/merge';
import PropTypes from 'prop-types';
import { useLocales } from 'src/locales';
import { useMemo, useState, useEffect } from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';

import { useSettingsContext } from 'src/components/settings';

// system
import { palette } from './palette';
import { shadows } from './shadows';
import { typography } from './typography';
// options
import RTL from './options/right-to-left';
import { customShadows } from './custom-shadows';
import { componentsOverrides } from './overrides';
import { createPresets } from './options/presets';
import { createContrast } from './options/contrast';
import { useAuthContext } from '../auth/hooks/index';

// ----------------------------------------------------------------------

export default function ThemeProvider({ children }) {
  const { currentLang } = useLocales();

  const { user } = useAuthContext();

  const settings = useSettingsContext();

  const [colorPreset, setColorPreset] = useState('default');

  useEffect(() => {
    if (user?.data?.current_company) {
      const companyTheme = user.data.companies[user.data.current_company]?.primary_color;
      if (companyTheme != null && companyTheme !== '') {
        setColorPreset(companyTheme);
      }
    }
  }, [user]);

  const presets = createPresets(colorPreset);

  const contrast = createContrast(settings.themeContrast, settings.themeMode);

  const memoizedValue = useMemo(
    () => ({
        palette: {
          ...palette(settings.themeMode),
          ...presets.palette,
          ...contrast.palette
        },
        customShadows: {
          ...customShadows(settings.themeMode),
          ...presets.customShadows
        },
        direction: settings.themeDirection,
        shadows: shadows(settings.themeMode),
        shape: { borderRadius: 8 },
        typography
      }),
    [
      settings.themeMode,
      settings.themeDirection,
      presets.palette,
      presets.customShadows,
      contrast.palette,
    ]
  );

  const theme = createTheme(memoizedValue);

  theme.components = merge(componentsOverrides(theme), contrast.components);

  const themeWithLocale = useMemo(
    () => createTheme(theme, currentLang.systemValue),
    [currentLang.systemValue, theme]
  );

  return (
    <MuiThemeProvider theme={themeWithLocale}>
      <RTL themeDirection={settings.themeDirection}>
        <CssBaseline />
        {children}
      </RTL>
    </MuiThemeProvider>
  );
}

ThemeProvider.propTypes = {
  children: PropTypes.node,
};
