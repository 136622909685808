import apiClient from './ApiService';

const getNationalHolidays = () => apiClient.get(`/national-holidays`);

const getWorkContractTypes = () => apiClient.get(`/contract-types`);

const getQualificationTypes = () => apiClient.get(`/qualification-types`);

const getNationalHolidaysByMonth = async (year, month) =>
  apiClient.get(`/national-holidays?year=${year}&month=${month}`);

const getHelpDeskCategories = (payroll_id) => apiClient.get(`/payroll/${payroll_id}/help-tickets-categories`);

const getNewsList = (payroll_id) => apiClient.get(`/payroll/${payroll_id}/news`);

const getNews = (payroll_id, news_id) => apiClient.get(`/payroll/${payroll_id}/news/${news_id}`);

const getFaqs = (payroll_id) => apiClient.get(`/payroll/${payroll_id}/faqs`);

const getExternalLinks = (payroll_id) => apiClient.get(`/payroll/${payroll_id}/links`);

const GeneralService = {
  getNationalHolidays,
  getNationalHolidaysByMonth,
  getHelpDeskCategories,
  getNewsList,
  getNews,
  getFaqs,
  getExternalLinks,
  getWorkContractTypes,
  getQualificationTypes,
};

export default GeneralService;
